
import {Vue, Component, Prop, Watch, Mixins, Emit} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class TahsilatlarChart extends Mixins(SimpleInputMixin) {
  @Prop() selectedMuvId: number | null | undefined;
  @Prop() selectedKurumId: number | null | undefined;
  @Prop()series!:Array<any>

  tahsilatOraniTitleChart = ["Toplam Tahsilat", "Tahsilat Oranı", "Derdest Kısmi Toplam", "Hitam Tahsilat"]

  options = {
    series: this.series,
    chart: {
      type: 'bar',
    },
    colors: ["#5FB3F6", "#BFC9D2"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '30%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık'
      ],
      labels: {
        style: {
          show: true,
          fontSize: '11px',
          color: '#A4A4A4',
        }
      }
    }
  };

}
